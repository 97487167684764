import axios from 'axios';
import {Limit} from "../model";

const useMock = false

export class LimitsApi {
    static readonly KEY = 'report';

    static async getLimits(peopleAccountId: string): Promise<Limit[]> {
        if (useMock) {
            return [
                {limit: 222, balance: 11, priority: 1,  checkUniqueAmount: false},
                {limit: 333, balance: 12, priority: 2,  checkUniqueAmount: true},
            ]
        }
        const {data} = await axios.get(`/api/router/v1/people/account/limit/${peopleAccountId}`);
        return data.data;
    }

    static async changeLimit(params: {
        peopleAccountId: string,
    } & Limit): Promise<void> {
        const {data} = await axios.put(`/api/router/v1/people/account/limit/${params.peopleAccountId}`, {params});
        return data.data;
    }
}
