import {Dialog, Transition} from "@headlessui/react";
import {Fragment, type HTMLAttributes} from "react";
import {useForm} from "react-hook-form";
import {useMutation, useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup';
import {PeopleApi, ProviderApi} from "../../../api";

const schema = yup.object({
    paymentMethodId: yup.string().required(),
    paymentProviderId: yup.string().required(),
});

export const AccountDialog = ({
                                     isOpen,
                                     onClose
                                 }: {
    isOpen: boolean;
    onClose: () => void;
} & HTMLAttributes<HTMLDivElement>) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    });


    const { data: providerList = [] } = useQuery({
        queryKey: [ProviderApi.KEY, 'provider'],
        queryFn: ProviderApi.getProvider,
    });


    const settlementMutation = useMutation({
        mutationFn: (data: yup.InferType<typeof schema>) => PeopleApi.createAccount(data),
        onSuccess: () => {
            toast.success('Account created');
        },
    });

    const onSubmit = async (data: yup.InferType<typeof schema>) => {
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[100]" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="bg-white px-8 py-10 rounded-3xl w-[768px]">
                                <div className="grid gap-8">
                                    <div className="grid grid-cols-[100px_1fr] items-center">
                                        <span>Payment Provider:</span>
                                        <select
                                            {...register('paymentProviderId', {
                                                required: true,
                                                valueAsNumber: true,
                                            })}
                                            className="input appearance-none w-full"
                                        >
                                            <option value="">Not selected</option>
                                            {providerList.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {errors.paymentProviderId && typeof errors.paymentProviderId.message === 'string' && (
                                        <span className="text-rose-700">{errors.paymentProviderId.message}</span>
                                    )}
                                    <div className="grid grid-cols-[100px_1fr] items-center">
                                        <span>Payment Method:</span>
                                        <input
                                            className="input"
                                            placeholder="Сумма (USDT)"
                                            {...register("paymentMethodId")}
                                            type="text"
                                        />
                                    </div>
                                    {errors.paymentMethodId && typeof errors.paymentMethodId.message === 'string' && (
                                        <span className="text-rose-700">{errors.paymentMethodId.message}</span>
                                    )}
                                    <button className="button button--block button--primary"
                                            disabled={settlementMutation.isLoading}
                                            onClick={handleSubmit(onSubmit)}>Сохранить
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}