import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';
import {LimitsApi, PeopleApi} from "../../api";
import {BackButton, Spinner, Switch} from '../../components';
import {AccountCredential, AccountCredentialsDialogAdd, AccountLimit} from "./ui";
import {useEffect, useState} from "react";
import {Limit} from "../../model";

export const AccountsDetailsPage = () => {
    const client = useQueryClient();
    const [enabled, setEnabled] = useState(false);
    const {id = ''} = useParams();

    const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);

    const {data} = useQuery({
        queryKey: [PeopleApi.KEY, 'getByMerchant', id],
        queryFn: () => PeopleApi.getAccountById(id!),
        enabled: Boolean(id),
    });

    const {data: limits} = useQuery({
        queryKey: [LimitsApi.KEY, 'getLimits', id],
        queryFn: () => LimitsApi.getLimits(id!),
        enabled: Boolean(id),
    });

    const toggleAccountEnabledMutation = useMutation({
        mutationFn: (id: string) => PeopleApi.toggleAccountEnabled(id),
        onSuccess: () => {
            client.refetchQueries([PeopleApi.KEY, 'getAccountById', id])
            setEnabled(!enabled)
        }
    })

    useEffect(() => {
        !!data && setEnabled(data.enabled);
    }, [data])

    if (!data || !limits) {
        return <Spinner/>;
    }

    return (
        <>
            <div className="flex flex-col gap-4">
                <div className="p-7 w-4/5 bg-white rounded-3xl row-g">
                    <div className="flex justify-between items-center pb-8">
                        <BackButton/>
                        <Switch checked={enabled}
                                onClick={() => toggleAccountEnabledMutation.mutate(id)}
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="uppercase font-semibold text-sm">Account Id</div>
                        <div className="font-semibold text-base text-green-500 flex flex-col">
                            {data.id}
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-3">
                        <div className="uppercase font-semibold text-sm">Provider Name</div>
                        <div className="font-semibold text-base text-green-500">
                            {data.providerName}
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-3">
                        <div className="uppercase font-semibold text-sm">Payment Method Name</div>
                        <div className="font-semibold text-base text-green-500">
                            {data.paymentMethodName}
                        </div>
                    </div>
                    <hr className="my-6 border-slate-100"/>
                    <div className="text-xs text-[#040304]">
                        {Array.isArray(data.credentials) && data.credentials.length ? <>
                            <div className="uppercase font-semibold text-sm flex items-center justify-between">
                                <span>Credentials</span>
                                <button
                                    className="flex-center h-8 w-fit px-2 shadow-1 rounded button--primary"
                                    onClick={() => setIsAccountDialogOpen(true)}
                                >
                                    Add
                                </button>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <span className="text-center uppercase">type</span>
                                <span className="text-center uppercase">value</span>
                                <span className="text-center uppercase">actions</span>
                            </div>
                            <div className="p-2 border-brand-1 border rounded flex flex-col gap-5 mt-2">
                                {data.credentials.map(credential => <AccountCredential
                                    key={Math.random()}
                                    credential={credential}
                                    peopleAccountId={id}
                                />)}
                            </div>
                        </> : null}
                        <div className="uppercase font-semibold text-sm mt-2">Limits</div>
                        <div className="grid grid-cols-5 justify-between items-center mt-2">
                            <span className="text-center uppercase">limit</span>
                            <span className="text-center uppercase">balance</span>
                            <span className="text-center uppercase">priority</span>
                            <span className="text-center uppercase">unique</span>
                            <span className="text-center uppercase">actions</span>
                        </div>
                        <div className="p-2 border-brand-1 border rounded gap-5 mt-2">
                            {Array.isArray(limits) ? limits.map(limit => <AccountLimit
                                key={Math.random()}
                                limit={limit}
                                peopleAccountId={id}
                            />) : (
                                <AccountLimit
                                    limit={limits as unknown as Limit}
                                    peopleAccountId={id}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isAccountDialogOpen && <AccountCredentialsDialogAdd isOpen={isAccountDialogOpen}
                                                                 onClose={() => setIsAccountDialogOpen(false)}/>}
        </>
    );
};
