import {BalanceReportCard, ProviderReportCard, TransactionsReportCard} from '../components';

export const ReportsPage = () => (
  <header>
    <h1 className="mb-4 text-4xl text-brand-6 font-bold">Reports</h1>
    <ul className="flex gap-4">
      <TransactionsReportCard />
      <BalanceReportCard />
      <ProviderReportCard />
    </ul>
  </header>
);
