import {useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useMutation} from "@tanstack/react-query";
import {PeopleApi} from "../../../api";
import {toast} from "react-toastify";
import {ConfirmDialog} from "../../../components";

const schema = yup.object({
    credentialType: yup.string().required(),
    value: yup.string().required(),
});

export const AccountCredential = (props: {
    credential: {
        credentialType: string;
        value: string;
    },
    peopleAccountId: string
}) => {
    const {credential, peopleAccountId} = props
    const [isEditable, setIsEditable] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const {
        register,
        handleSubmit,
    } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    });

    const changeAccountCredentialMutation = useMutation({
        mutationFn: (data: yup.InferType<typeof schema>) => PeopleApi.changeAccountCredentials({
            ...data,
            peopleAccountId
        }),
        onSuccess: () => {
            toast.success('Successfully changed');
        }
    })

    const removeAccountCredentialMutation = useMutation({
        mutationFn: () => PeopleApi.removeAccountCredentials(peopleAccountId),
        onSuccess: () => {
            toast.success('Successfully removed');
        }
    })

    const onSubmit = async (data: yup.InferType<typeof schema>) => {
        changeAccountCredentialMutation.mutate(data)
    };

    const onContinue = () => {
        setIsOpen(false);
        removeAccountCredentialMutation.mutate();
    };

    return (
        <>
            {isEditable ? (
                <form className="flex justify-between items-center" onSubmit={handleSubmit(onSubmit)}>
                    <span className="text-center">
                        <input
                            defaultValue={credential.credentialType}
                            {...register('credentialType')}
                            className="p-1 border-brand-1 w-full border-2 rounded outline-none text-center"
                        />
                    </span>
                    <span className="text-center">
                        <input
                            defaultValue={credential.value}
                            {...register('value')}
                            className="p-1 border-brand-1 w-full border-2 rounded outline-none text-center"
                        />
                    </span>
                    <span className="text-center w-fit flex items-center gap-2">
                         <button
                             className="flex flex-center w-fit outline-none p-2 text-white active:bg-brand-1/25 rounded-lg text-sm bg-blue-300 whitespace-nowrap"
                             onClick={() => setIsEditable(true)}
                         >
                             Edit
                         </button>
                         <button
                             className="flex outline-none flex-center w-fit  p-2 text-white active:bg-brand-1/25 rounded-lg text-sm bg-rose-400 whitespace-nowrap"
                             onClick={(event) => {
                                 event.preventDefault()
                                 setIsOpen(true)
                             }}
                             disabled={removeAccountCredentialMutation.isLoading}
                         >
                            Remove
                         </button>
                    </span>
                </form>
            ) : (
                <div className="flex justify-between items-center gap-2">
                    <span className="text-center">{credential.credentialType}</span>
                    <span className="text-center truncate" title={credential.value}>{credential.value}</span>
                    <span className="text-center w-fit flex items-center gap-2">
                         <button
                             className="flex flex-center w-fit outline-none p-2 text-white active:bg-brand-1/25 rounded-lg text-sm bg-blue-300 whitespace-nowrap"
                             onClick={() => setIsEditable(false)}
                         >
                             Edit
                         </button>
                         <button
                             className="flex outline-none flex-center w-fit  p-2 text-white active:bg-brand-1/25 rounded-lg text-sm bg-rose-400 whitespace-nowrap"
                             onClick={(event) => {
                                 event.preventDefault()
                                 setIsOpen(true)
                             }}
                             disabled={removeAccountCredentialMutation.isLoading}
                         >
                            Remove
                         </button>
                    </span>
                </div>
            )}
            <ConfirmDialog
                isOpen={isOpen}
                onClick={() => setIsOpen(false)}
                onContinue={onContinue}
                title="Credential remove"
                paragraph={
                    "Are you sure you want to remove this one?"
                }
            />
        </>
    )

}