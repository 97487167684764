import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Spinner } from '../../components';
import { PeopleApi } from "../../api";
import cx from "classnames";
import { useState } from "react";
import { AccountDialog } from "./ui";
import { useProfile } from '../../hooks';
import {generatePath, useNavigate} from "react-router-dom";
import {RoutePath} from "../../config";

export const AccountsPage = () => {
  const profile = useProfile();
  const client = useQueryClient();

  const navigate = useNavigate();

  const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);

  const { data } = useQuery({
    queryKey: [PeopleApi.KEY, 'getAccounts'],
    queryFn: () => PeopleApi.getAccounts(),
  });

  const toggleAccountEnabledMutation = useMutation({
      mutationFn: (id: string) => PeopleApi.toggleAccountEnabled(id),
      onSuccess: () => {
          client.refetchQueries([PeopleApi.KEY, 'getAccounts'])
      }
  })

  if (!data) return <Spinner />;

  const canToggleAccount= !!profile?.permissions.includes('people.account.edit');

  return (
      <div className="w-2/3">
        <h1 className="mb-4 text-4xl text-brand-6 font-bold flex justify-between">
          <span>Account</span>
          <button
              className="flex flex-center h-10 p-2 text-white active:bg-brand-1/25 rounded-lg text-sm bg-brand-1 "
              onClick={() => setIsAccountDialogOpen(true)} >
              New Account
          </button>
        </h1>
        <div className="p-7 w-5/5 bg-white rounded-3xl relative grow">
        <div className="overflow-x-auto">
          <table className="min-w-full text-start text-sm font-light text-surface w-5/5">
            <thead className="border-b border-neutral-200 font-medium">
            <tr className="">
              <th scope="col" className="pb-5 uppercase text-center">id</th>
              <th scope="col" className="pb-5 uppercase text-center">provider name</th>
              <th scope="col" className="pb-5 uppercase text-center">bank name</th>
              <th scope="col" className="pb-5 uppercase text-center w-28">enabled</th>
            </tr>
            </thead>
            <tbody>
            {data.map((item) => (
                <tr
                    key={item.id}
                    className=" border-b hover:bg-brand-1/5 cursor-pointer"
                    onClick={() =>
                        navigate(
                            generatePath(RoutePath.AccountDetails, {
                                id: item.id,
                            }), {
                                state: {
                                    item
                                }
                            }
                        )
                    }
                >
                  <td className=" py-2 text-center">{item.id}</td>
                  <td className=" py-2 text-center">{item.providerName}</td>
                  <td className="overflow-hidden text-ellipsis w-96 px-2" title={item.bankName}>{item.bankName}</td>
                  <td
                      className={cx(' text-white text-center py-2', {
                        'bg-green-800': item.enabled,
                        'bg-rose-800': !item.enabled,
                      })}
                      onClick={(event) => {
                          event.stopPropagation();

                          if (!canToggleAccount) return;
                          toggleAccountEnabledMutation.mutate(item.id)}
                      }
                  >
                      {item.enabled ? 'on' : 'off'}
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
        </div>
        {isAccountDialogOpen && <AccountDialog isOpen={isAccountDialogOpen} onClose={() => setIsAccountDialogOpen(false)} />}
      </div>
  );
};