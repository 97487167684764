import {useMutation, useQuery} from '@tanstack/react-query';
import {useForm} from 'react-hook-form';
import {ProviderApi, ProviderReportParams, ReferenceApi, ReportApi} from '../../api';
import {useProfile} from '../../hooks';
import {formatDateForInput} from '../../utils';

export const ProviderReportCard= () => {
  const profile = useProfile();

  const { data: currencyList = [] } = useQuery({
    queryKey: [ReferenceApi.KEY, 'currency'],
    queryFn: ReferenceApi.currency,
  });

  const { data: providerList = [] } = useQuery({
    queryKey: [ProviderApi.KEY, 'provider'],
    queryFn: ProviderApi.getProvider,
  });

  const { handleSubmit, register, watch } = useForm<ProviderReportParams>();
  
  const periodStart = watch('periodStart');
  const periodEnd = watch('periodEnd');

  const { mutate } = useMutation({
    mutationFn: ReportApi.provider,
  });

  const onSubmit =  (data: ProviderReportParams) => {
        mutate(data);
    }

  if (!profile || !profile.permissions.includes('report.read.provider')) {
    return null;
  }

  return (
    <li className="p-4 rounded-2xl shadow-1 bg-white">
      <h2 className="mb-5 text-lg font-bold text-center">Provider</h2>
      <form className="grid gap-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-4">
          <label>
            <span className="mb-1 block text-brand-2 text-sm pl-4">Date From</span>
            <input
                {...register('periodStart', {
                  required: true,
                  valueAsDate: true,
                })}
                className="input w-full"
                max={formatDateForInput(new Date(periodEnd))}
                type="date"
            />
          </label>
          <label>
            <span className="mb-1 block text-brand-2 text-sm pl-4">Date To</span>
            <input
                {...register('periodEnd', {
                  required: true,
                  valueAsDate: true,
                })}
                className="input w-full"
                min={formatDateForInput(new Date(periodStart))}
                type="date"
            />
          </label>
          <label>
            <span className="mb-1 block text-brand-2 text-sm pl-4">Currency</span>
            <select
                {...register('currencyId', {
                  required: true,
                  valueAsNumber: true,
                })}
                className="input appearance-none w-full"
            >
              <option value="">Not selected</option>
              {currencyList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.code}
                  </option>
              ))}
            </select>
          </label>
          <label>
            <span className="mb-1 block text-brand-2 text-sm pl-4">Provider</span>
            <select
                {...register('providerId', {
                  required: true,
                  valueAsNumber: true,
                })}
                className="input appearance-none w-full"
            >
              <option value="">Not selected</option>
              {providerList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
              ))}
            </select>
          </label>
        </div>

        <button className="button button--block button--primary" type="submit">
          Get report
        </button>
      </form>
    </li>
  );
};
