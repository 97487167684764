import {useQuery} from '@tanstack/react-query';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useCopyToClipboard} from 'usehooks-ts';
import {InvoiceApi} from '../../api';
import {BackButton, InvoiceStatus, InvoiceStatusSelector, Money, Spinner, Tag} from '../../components';
import {TagType} from '../../model';
import {useProfile} from '../../hooks';
import {formatDateUTC} from "../../utils/lib";
import {InvoiceAmountChangeModal,InvoiceSupportIssues, InvoiceErrors, InvoiceEventsLogsBlock} from "./ui";
import {InvoiceEvents} from "./ui/InvoiceEvents";

export const InvoiceDetailsPage = () => {
    const profile = useProfile();
    const {id} = useParams();

    const [, copy] = useCopyToClipboard();
    const [isOpen, setIsOpen] = useState(false);

    const {data: invoice} = useQuery({
        queryKey: [InvoiceApi.KEY, 'getByMerchant', id],
        queryFn: () => InvoiceApi.getById(id!),
        enabled: Boolean(id),
    });

    const canChangeAmount = profile?.permissions.includes('invoice.amount.change');
    const canChangeInvoiceStatus = profile?.permissions.includes('invoice.status.change');

    if (!invoice) {
        return <Spinner/>;
    }

    const isProviderTextIdVisible = !!(profile?.permissions.includes('invoice.provider.read') && invoice.providerInvoiceId)

    return (
        <>
            <div className="flex flex-col gap-4">
                <div className="p-7 w-3/5 bg-white rounded-3xl row-g">
                    <div className="flex justify-between items-center pb-8">
                        <div className="flex justify-between items-center gap-5">
                            <BackButton/>
                            <span className="flex items-center gap-2 text-lg">
                            Transaction
                                {invoice.isTestProcessing && (
                                    <Tag className="opacity-50" type={TagType.Neutral}>
                                        Test
                                    </Tag>
                                )}
                                <sup className="capitalize text-xs text-brand-2">
                              {formatDateUTC(invoice.statusDate)}
                            </sup>
                          </span>
                        </div>
                        <Tag
                            className="px-2 py-1 cursor-pointer"
                            onClick={() => {
                                copy(String(invoice.invoiceId));
                                toast.success('Invoice ID copied to clipboard');
                            }}
                            type={TagType.Primary}
                        >
                            Copy
                        </Tag>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="uppercase font-semibold text-sm">Amount</div>
                        <div className="font-semibold text-base text-green-500 flex flex-col">
                            <Money amount={invoice.amount} currency={invoice.currency}/>
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-3">
                        <div className="uppercase font-semibold text-sm">Fee</div>
                        <div className="font-semibold text-base text-green-500">
                            <Money amount={invoice.fee ? invoice.fee : 0} currency={invoice.currency}/>
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-3">
                        {canChangeAmount && <Tag
                            className="px-2 py-1 cursor-pointer"
                            onClick={() => {
                                setIsOpen(true);
                            }}
                            type={TagType.Primary}
                        >
                            Change amount
                        </Tag>}
                    </div>
                    <hr className="my-6 border-slate-100"/>
                    <div className="text-xs text-[#040304]">
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Flow Type</span>{' '}
                            <span className="capitalize">{invoice.flowType.code.toLowerCase()}</span>
                        </div>
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Invoice ID</span> <span>{invoice.invoiceId}</span>
                        </div>
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Merchant Invoice ID</span>
                            <span className="break-all whitespace-normal">{invoice.merchantInvoiceId}</span>
                        </div>
                        {isProviderTextIdVisible && <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Provider Invoice ID</span>
                            <span className="break-all whitespace-normal">{invoice.providerInvoiceId}</span>
                        </div>}
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">User ID</span>
                            <span className="break-all">
                            {invoice.customer.externalId}
                          </span>
                        </div>
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Owner name</span>
                            <span>
                            {invoice.customer.people.firstName}&nbsp;{invoice.customer.people.lastName}
                          </span>
                        </div>
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Terminal ID</span>
                            <span>               {invoice.terminal?.id}            </span>
                        </div>
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Terminal name</span>
                            <span>               {invoice.terminal?.name}            </span>
                        </div>
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Status</span>
                            <span className="w-fit">
                              {(invoice.isTestProcessing || canChangeInvoiceStatus)
                                  ? <InvoiceStatusSelector invoiceId={invoice.invoiceId}
                                                           statusId={invoice.invoiceStatus.id}
                                                           status={invoice.invoiceStatus}/>
                                  : <InvoiceStatus status={invoice.invoiceStatus}/>
                              }
                            </span>
                        </div>
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Status date</span>{' '}
                            <span>
                            {formatDateUTC(invoice.statusDate, 'long')}
                          </span>
                        </div>
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Currency</span>
                            <span>{invoice.currency.code}</span>
                        </div>
                        <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                            <span className="w-40 text-brand-2">Payment method</span>
                            <span>{invoice.paymentMethod?.paymentMethod?.name ?? '-'}</span>
                        </div>
                        {profile?.permissions.includes('invoice.credentials.read') && (
                            <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                                <span className="w-40 text-brand-2">Credentials</span>
                                <span>{invoice.account?.value}</span>
                            </div>
                        )}
                        {invoice.credentials && (
                            <> {invoice.credentials?.bankName && (
                                <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                                    <span className="w-40 text-brand-2">Bank name</span>
                                    <span>{invoice.credentials?.bankName}</span>
                                </div>)
                            }
                                {invoice.credentials?.owner && (
                                    <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                                        <span className="w-40 text-brand-2">Owner</span>
                                        <span>{invoice.credentials?.owner}</span>
                                    </div>)
                                }{invoice.credentials?.provider && (
                                    <div className="grid grid-cols-[1fr_2fr] gap-4 mb-5">
                                        <span className="w-40 text-brand-2">Provider</span>
                                        <span>{invoice.credentials?.provider}</span>
                                    </div>)
                                }
                            </>
                        )}
                    </div>
                    <InvoiceEvents/>
                </div>
                <InvoiceErrors/>
                <InvoiceEventsLogsBlock/>
                <InvoiceSupportIssues invoice={invoice}/>
            </div>
            <InvoiceAmountChangeModal invoice={invoice} isOpen={isOpen} setIsOpen={setIsOpen}/>
        </>
    );
};
