import cx from "classnames";
import type { HTMLAttributes } from "react";
import { Fragment } from "react";
import { Switch as HeadlessUiSwitch } from "@headlessui/react";

type SwitchProps = { checked: boolean } & HTMLAttributes<HTMLButtonElement>;

export const Switch = ({ checked, onClick }: SwitchProps) => {
  return (
      <HeadlessUiSwitch checked={checked} as={Fragment}>
        {({ checked }) => (
          <button
            onClick={onClick}
            className={cx(
              "group inline-flex h-6 w-11 items-center rounded-full bg-brand-1",
            )}
          >
            <span
              className={cx(
                "size-4 rounded-full transition",
                checked
                  ? "bg-green-500 translate-x-6"
                  : "bg-rose-500 translate-x-1",
              )}
            />
          </button>
        )}
      </HeadlessUiSwitch>
  );
};
