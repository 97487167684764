import axios from "axios";
import type {Account, CreateAccountParams, Profile} from "../model";

const useMock = false

export class PeopleApi {
    static readonly KEY = "people";

    static async me(): Promise<Profile> {
        const {data} = await axios.get("/api/v1/people/me");
        return data.data;
    }

    static async getAccounts(): Promise<Account[]> {
        if (useMock) {
            return Array(20).fill(null).map((_, index) => ({
                id: Math.random(),
                providerName: index + 'providerName',
                bankName: index + 'bankName',
                enabled: true
            })) as unknown as Account[];
        }

        const {data} = await axios.get("/api/v1/people/account");
        return data.data;
    }

    static async getAccountById(id: string): Promise<Account> {
        if (useMock) {
            return {
                id: String(Math.random()),
                providerName: 'providerName',
                paymentMethodName: 'paymentMethodName',
                bankName: 'bankName',
                enabled: true,
                credentials: [
                    {
                        value: 'string',
                        credentialType: 'string',
                        paramType: 'string',
                    },
                    {
                        value: 'string',
                        credentialType: 'string',
                        paramType: 'string',
                    }
                ]
            };
        }
        const {data} = await axios.get(`/api/v1/people/account/${id}`);
        return data.data;
    }

    static async toggleAccountEnabled(id: string): Promise<Account[]> {
        return await axios.patch(`/api/v1/people/account/${id}`);
    }

    static async createAccount(params: CreateAccountParams): Promise<Account> {
        const {data} = await axios.post("/api/v1/people/create", {params});
        return data.data;
    }

    static async changeAccountCredentials(params: { credentialType: string; value: string, peopleAccountId: string }): Promise<boolean> {
        if (useMock) {
            return true
        }
        const {data} = await axios.post("/api/v1/people/create", {params});
        return data.data;
    }

    static async removeAccountCredentials(peopleAccountId: string): Promise<boolean> {
        if (useMock) {
            return true
        }
        const {data} = await axios.post("/api/v1/people/create");
        return data.data;
    }

    static async switchTerminalMode() {
        return await axios.patch(`/api/v1/people/me/testDashboard`);
    }
}
