import {ChangeEvent, useState} from 'react';
import { useAllTerminals } from '../../hooks';
import { useTerminalStore } from '../../store';

export const TerminalSelector = () => {
  const { terminalId, changeTerminalId } = useTerminalStore();
  const terminals = useAllTerminals();

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    changeTerminalId(event.target.value);
  };

  if (terminals.length === 0) {
    return null;
  }

  return (
    <div className="flex gap-2 items-center">
      <span>Terminal:</span>
      <select
        className="h-full input px-2 py-1 rounded-lg text-xs appearance-none"
        onChange={onChange}
        value={terminalId}
      >
        {[{ id: '', name: 'All terminals' }, ...terminals].map((terminal) => (
          <option key={terminal.id} value={terminal.id}>
            {terminal.name}
          </option>
        ))}
      </select>
    </div>
  );
};


export const TerminalSelectorLocal = ({setValue}: {setValue: any}) => {
    const [terminalId, changeTerminalId] = useState('');
    const terminals = useAllTerminals();

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        changeTerminalId(event.target.value);
        setValue(event.target.value);
    };

    if (terminals.length === 0) {
        return null;
    }

    setValue('terminalId', terminals[0].id)

    return (
        <div className="grid grid-cols-[100px_1fr] items-center">
            <span>Terminal:</span>
            <select
                className="input"
                onChange={onChange}
                value={terminalId}
            >
                {terminals.map((terminal) => (
                    <option key={terminal.id} value={terminal.id}>
                        {terminal.name}
                    </option>
                ))}
            </select>
        </div>
)
    ;
};