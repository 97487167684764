import axios from 'axios';

type Provider = {
    id: string,
    name: string
    code: string
}

export class ProviderApi {
  static readonly KEY = 'provider';

  static async getProvider(): Promise<Provider[]> {
    const { data } = await axios.get('/api/v1/provider')
    return data.data
  }
}
