import axios from 'axios';
import { format } from 'date-fns';

export interface ReportParams {
  terminalId?: string;
  periodEnd: string;
  periodStart: string;
}

export interface BalanceReportParams extends ReportParams {
  currencyId: number;
}

export interface ProviderReportParams extends BalanceReportParams {
  providerId: number;
}

const formatDate = (date: string) => format(date, 'yyyy-MM-dd')

export class ReportApi {
  static readonly KEY = 'report';

  static async balance(params: BalanceReportParams): Promise<void> {
    const periodEnd = formatDate(params.periodEnd);
    const periodStart = formatDate(params.periodStart);

    const queryParams: BalanceReportParams = {
      periodEnd,
      periodStart,
      currencyId: params.currencyId,
    };

    if (!!params.terminalId) {
      queryParams.terminalId = params.terminalId;
    }

    const { data } = await axios.get('/api/v1/report/balance', {
      params: queryParams,
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Balance_${periodStart}_${periodEnd}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  static async invoice(params: ReportParams): Promise<void> {
    const periodEnd = formatDate(params.periodEnd);
    const periodStart = formatDate(params.periodStart);

    const queryParams: ReportParams = {
      periodEnd,
      periodStart,
    };

    if (!!params.terminalId) {
      queryParams.terminalId = params.terminalId;
    }

    const { data } = await axios.get('/api/v1/report/invoice', {
      params: queryParams,
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Transactions_${periodStart}_${periodEnd}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  static async provider(params: ProviderReportParams): Promise<void> {
    const periodEnd = formatDate(params.periodEnd);
    const periodStart = formatDate(params.periodStart);

    const queryParams: ProviderReportParams = {
      periodEnd,
      periodStart,
      currencyId: params.currencyId,
      providerId: params.providerId
    };

    const { data } = await axios.get('/api/v1/report/provider', {
      params: queryParams,
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Provider_${periodStart}_${periodEnd}.csv`);
    document.body.appendChild(link);
    link.click();
  }
}
