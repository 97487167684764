import type { Locale } from '@eo-locale/core';
import { TranslationsProvider } from '@eo-locale/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContextProvider, PrivateRoute } from './components';
import { RoutePath } from './config';
import { InnerPageLayout } from './layouts';
import enMessages from './locale/en.json';
import ruMessages from './locale/ru.json';
import uzMessages from './locale/uz.json';
import {
  ChangePasswordPage,
  InvoiceDetailsPage,
  InvoicesPage,
  LoginPage,
  NotificationsPage,
  PaymentPage,
  ReportsPage,
  SettingsPage,
  TerminalsPage,
  MonitoringPage,
  AccountsPage,
  AccountsDetailsPage,
} from './pages';
import './styles.css';
import { MerchantPage } from './pages/MerchantPage';

const LOCALES: Locale[] = [
  {
    language: 'en',
    messages: enMessages,
  },
  {
    language: 'ru',
    messages: ruMessages,
  },
  {
    language: 'uz',
    messages: uzMessages,
  },
];

const availableLanguages = LOCALES.map((locale) => locale.language);

const getLanguageFromSearchParams = (searchParams: URLSearchParams): string => {
  const language = searchParams.get('lang')?.toLowerCase();
  if (language && availableLanguages.includes(language)) {
    return language;
  }

  return 'en';
};

export const App: FC = () => {
  const [queryClient] = useState(new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthContextProvider>
          <Pages />
        </AuthContextProvider>
      </BrowserRouter>
      <ToastContainer />
    </QueryClientProvider>
  );
};

const Pages: FC = () => {
  const [searchParams] = useSearchParams();
  const language = getLanguageFromSearchParams(searchParams);

  return (
    <TranslationsProvider language={language} locales={LOCALES}>
      <Routes>
        <Route path={RoutePath.Payment} element={<PaymentPage />} />
        <Route path={RoutePath.Login} element={<LoginPage />} />
        <Route
          element={
            <PrivateRoute>
              <InnerPageLayout />
            </PrivateRoute>
          }
        >
          <Route path={RoutePath.Accounts} element={<AccountsPage />} />
          <Route path={RoutePath.AccountDetails} element={<AccountsDetailsPage />} />
          <Route path={RoutePath.ChangePassword} element={<ChangePasswordPage />} />
          <Route path={RoutePath.Merchant} element={<MerchantPage />} />
          <Route path={RoutePath.Monitoring} element={<MonitoringPage />} />
          <Route path={RoutePath.Terminals} element={<TerminalsPage />} />
          <Route path={RoutePath.Notifications} element={<NotificationsPage />} />
          <Route path={RoutePath.Settings} element={<SettingsPage />} />
          <Route path={RoutePath.Invoices} element={<InvoicesPage />} />
          <Route path={RoutePath.InvoiceDetails} element={<InvoiceDetailsPage />} />
          <Route path={RoutePath.Reports} element={<ReportsPage />} />
        </Route>
        <Route path="*" element={<Navigate replace to={RoutePath.Login} />} />
      </Routes>
    </TranslationsProvider>
  );
};
