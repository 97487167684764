import type { FC } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { ReactComponent as ExitIcon } from '../assets/icons/exit.svg';
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { ReactComponent as SupportIcon } from '../assets/icons/support.svg';
import { Header, Logo } from '../components';
import { RoutePath } from '../config';
import { useAuthContext, useProfile } from '../hooks';
import { ChartBarIcon } from '@heroicons/react/16/solid';

export const InnerPageLayout: FC = () => {
  const profile = useProfile();
  const { signOut } = useAuthContext();
  const navigate = useNavigate();

  const isAccountsVisible = profile && profile.permissions.includes('people.account')

  const isMonitoringVisible = profile
    && profile.permissions.includes('terminal.statistic.settings')
    && profile.permissions.includes('data.terminal.statistic');

  const isMerchantVisible = profile
    && profile.permissions.includes('merchant.read');

  return (
    <div className="flex">
      <aside className="flex-[0_0_283px] flex flex-col h-screen">
        <header className="my-8 px-5 cursor-pointer" onClick={() => navigate(RoutePath.Invoices)}>
          <Logo className="text-2xl" />
        </header>
        <nav className="flex-1 px-3">
          <ul className="grid gap-2 text-brand-8 text-sm">
            <NavLink className="nav-item" to={RoutePath.Invoices}>
              <span className="flex-center h-10 w-10 bg-brand-1 rounded-lg">
                <HomeIcon />
              </span>
              Dashboard
            </NavLink>
            {profile?.permissions.includes('merchant.read.report') && (
              <NavLink className="nav-item" to={RoutePath.Reports}>
                <span className="flex-center h-10 w-10 bg-brand-1 rounded-lg">
                  <SettingsIcon />
                </span>
                Reports
              </NavLink>
            )}
            {isMerchantVisible && (
              <NavLink className="nav-item" to={RoutePath.Merchant}>
                <span className="flex-center h-10 w-10 bg-brand-1 rounded-lg">
                  <SettingsIcon />
                </span>
                Merchant
              </NavLink>
            )}
            <NavLink className="nav-item" to={RoutePath.Terminals}>
              <span className="flex-center h-10 w-10 bg-brand-1 rounded-lg">
                <SettingsIcon />
              </span>
              Terminals
            </NavLink>
            {isMonitoringVisible && (
              <NavLink className="nav-item" to={RoutePath.Monitoring}>
                <span className="flex-center h-10 w-10 bg-brand-1 rounded-lg">
                  <ChartBarIcon className="h-6 w-6 text-white" />
                </span>
                Monitoring
              </NavLink>
            )}
            {isAccountsVisible && (
                <NavLink className="nav-item" to={RoutePath.Accounts}>
                <span className="flex-center h-10 w-10 bg-brand-1 rounded-lg">
                  <ChartBarIcon className="h-6 w-6 text-white" />
                </span>
                  Accounts
                </NavLink>
            )}
          </ul>
        </nav>
        <footer className="p-8 text-brand-8">
          <a
            className="mb-4 flex items-center gap-4 text-brand-10"
            href={RoutePath.Settings}
          >
            <span className="flex-center h-10 w-10 bg-brand-1 rounded-lg">
              <SettingsIcon />
            </span>
            Settings
          </a>
          <a
            className="mb-4 flex items-center gap-4 text-brand-10"
            href={process.env.REACT_APP_SUPPORT_URL}
            rel="noreferrer"
            target="_blank"
          >
            <span className="flex-center h-10 w-10 bg-brand-1 rounded-lg">
              <SupportIcon />
            </span>
            Support
          </a>
          <button className="flex items-center gap-4 text-brand-10" onClick={signOut} type="button">
            <span className="flex-center h-10 w-10 bg-brand-10 rounded-lg">
              <ExitIcon />
            </span>
            Log Out
          </button>
        </footer>
      </aside>
      <main className="p-8 bg-[#F8F7FE] w-full h-screen overflow-auto flex flex-col relative">
        <Header />
        <Outlet />
      </main>
    </div>
  );
};
