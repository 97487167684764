import axios from 'axios';

export class SettlementApi {
    static readonly KEY = 'settlement';

    static async settlement(params: {
        terminalId: string;
        baseAmount: number;
        settlementAmount: number
    }) {
        const {data} = await axios.post('/api/v1/settlement', params);
        return data;
    }
}
