import {Dialog, Transition} from "@headlessui/react";
import {Fragment, type HTMLAttributes} from "react";
import {useForm} from "react-hook-form";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup';
import {SettlementApi} from "../../api";
import {useTerminalStore} from "../../store";

const schema = yup.object({
    baseAmount: yup.number().required(),
    settlementAmount: yup.number().required(),
});

export const SettlementDialog = ({
                                     isOpen,
                                     onClose
                                 }: {
    isOpen: boolean;
    onClose: () => void;
} & HTMLAttributes<HTMLDivElement>) => {
    const { terminalId } = useTerminalStore();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    });

    const settlementMutation = useMutation({
        mutationFn: (data: yup.InferType<typeof schema>) => SettlementApi.settlement({
            ...data,
            terminalId: terminalId!,
        }),
        onSuccess: () => {
            toast.success('Successfully created');
        },
    });

    const onSubmit = async (data: yup.InferType<typeof schema>) => {
       !!terminalId && settlementMutation.mutate(data);
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[100]" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="bg-white px-8 py-10 rounded-3xl w-[768px]">
                                <div className="grid gap-8">
                                    <div className="grid grid-cols-[100px_1fr] items-center">
                                        <span>Сумма:</span>
                                        <input className="input" placeholder="Сумма" {...register("baseAmount")}
                                               type="text"/>
                                    </div>
                                    {errors.baseAmount && typeof errors.baseAmount.message === 'string' && (
                                        <span className="text-rose-700">{errors.baseAmount.message}</span>
                                    )}
                                    <div className="grid grid-cols-[100px_1fr] items-center">
                                        <span>Сумма (USDT):</span>
                                        <input
                                            className="input"
                                            placeholder="Сумма (USDT)"
                                            {...register("settlementAmount")}
                                            type="text"
                                        />
                                    </div>
                                    {errors.settlementAmount && typeof errors.settlementAmount.message === 'string' && (
                                        <span className="text-rose-700">{errors.settlementAmount.message}</span>
                                    )}
                                    <button className="button button--block button--primary"
                                            disabled={settlementMutation.isLoading}
                                            onClick={handleSubmit(onSubmit)}>Сохранить
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}