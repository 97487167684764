import {useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useMutation} from "@tanstack/react-query";
import {LimitsApi} from "../../../api";
import {toast} from "react-toastify";
import {Limit} from "../../../model";

const schema = yup.object({
    limit: yup.number().required(),
    balance: yup.number().required(),
    priority: yup.number().required(),
    checkUniqueAmount: yup.boolean(),
});

export const AccountLimit = (props: { limit: Limit, peopleAccountId: string }) => {
    const [isEditable, setIsEditable] = useState(false);
    const {limit, peopleAccountId} = props
    const {
        register,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    });

    const changeAccountLimitMutation = useMutation({
        // @ts-ignore
        mutationFn: (data: yup.InferType<typeof schema>) => LimitsApi.changeLimit({...data, peopleAccountId}),
        onSuccess: () => {
            toast.success('Successfully changed');
        }
    })

    const onSubmit = async (data: yup.InferType<typeof schema>) => {
        changeAccountLimitMutation.mutate(data)
    };

    return isEditable ? (
        <form className="grid grid-cols-5 justify-between items-center" onSubmit={handleSubmit(onSubmit)}>
            <span className="text-center px-0.5">
                <input
                    defaultValue={limit.limit}
                    {...register('limit')}
                    className="p-1 border-brand-1 w-full border-2 rounded outline-none text-center px-0.5"
                />
            </span>
            <span className="text-center px-0.5">
                <input
                    defaultValue={limit.balance}
                    {...register('balance')}
                    className="p-1 border-brand-1 w-full border-2 rounded outline-none text-center px-0.5"
                />
            </span>
            <span className="text-center px-0.5">
                <input
                    defaultValue={limit.priority}
                    {...register('priority')}
                    className="p-1 border-brand-1 w-full border-2 rounded outline-none text-center px-0.5"
                />
            </span>
            <span className="text-center px-0.5">
                <input
                    type="checkbox"
                    checked={limit.checkUniqueAmount}
                    {...register('checkUniqueAmount')}
                    className="p-1 border-brand-1 w-full border-2 rounded outline-none text-center px-0.5"
                />
            </span>
            <span className="text-center px-0.5 flex items-center gap-2 w-full">
                 <button
                     className="flex flex-center m-auto w-fit p-2 text-white active:bg-brand-1/25 rounded-lg text-sm bg-blue-300 whitespace-nowrap"
                     onClick={() => setIsEditable(false)}>
                     Save
                  </button>
            </span>
        </form>
    ) : (
        <div className="grid grid-cols-5 justify-between items-center">
            <span className="text-center px-0.5">{limit.limit ?? 'not set'}</span>
            <span className="text-center px-0.5">{limit.balance ?? 'not set'}</span>
            <span className="text-center px-0.5">{limit.priority ?? 'not set'}</span>
            <span className="text-center px-0.5">
                <input
                    type="checkbox"
                    checked={limit.checkUniqueAmount}
                    disabled
                    className="p-1 border-brand-1 w-full border-2 rounded outline-none text-center px-0.5"
                />
            </span>
            <span className="text-center px-0.5 flex items-center gap-2 w-full">
                 <button
                     className="flex flex-center m-auto w-fit p-2 text-white active:bg-brand-1/25 rounded-lg text-sm bg-blue-300 whitespace-nowrap"
                     onClick={() => setIsEditable(true)}>
                     Edit
                  </button>
            </span>
        </div>
    )
}