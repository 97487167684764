import type {Balance} from '../../model';
import {Button, Money} from '../atoms';
import {useState} from "react";
import {SettlementDialog} from "./SettlementDialog";
import {useProfile} from "../../hooks";
import {useTerminalStore} from "../../store";

type BalanceWidgetCardProps = {
    item: Balance | undefined
    title: 'balance' | 'deposit' | 'withdrawal'
}

export const BalanceWidgetCard = ({item, title}: BalanceWidgetCardProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const profile = useProfile();
    const { terminalId } = useTerminalStore();
    const isSettlementVisible = !!profile?.permissions.includes('settlement.create')

    const onClose = () => {
        setIsOpen(false);
    }

    return (
        <>
            <li className="flex-1 p-4 rounded-2xl shadow-1 bg-white text-center grid grid-rows-[auto_auto] relative">
                <span className="block text-brand-2 first-letter:uppercase">{title}</span>
                {title === 'balance' && isSettlementVisible &&  <Button className="absolute rounded-full outline-0 cursor-pointer z-10 inset-0 ml-auto my-auto right-4 w-fit" onClick={() => setIsOpen(true)}>
                    &uarr;&darr;
                </Button>}
                <span className="relative h-[30px] flex-center">
                  {!!item?.balance
                      ? <strong>
                          <Money amount={item?.balance[0].sum} currency={item?.balance[0].currency}/>
                      </strong>
                      : (
                          <div className="animate-pulse">
                              <div className="h-[20px] rounded bg-brand-2 w-fit text-brand-2">{title}</div>
                          </div>
                      )
                  }
                </span>
            </li>
            {isSettlementVisible && !!terminalId && <SettlementDialog isOpen={isOpen} onClose={onClose}/>}
        </>
    );
};
