export enum RoutePath {
    ChangePassword = '/settings/change-password',
    InvoiceDetails = '/invoices/:id',
    Invoices = '/invoices',
    Login = '/login',
    Notifications = '/notifications',
    Payment = '/payment/:formId',
    Reports = '/reports',
    Merchant = '/merchant',
    Settings = '/settings',
    Terminals = '/terminals',
    Monitoring = '/monitoring',
    Accounts = '/accounts',
    AccountDetails = '/accounts/:id',
}
